import React, { useEffect, useState } from 'react';
import '../components/shared/Navbar.css';
import { Typography, Grid, Box, Container } from '@mui/material';

const Contact: React.FC = () => {

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 992);

  const contents = [
    {
      title: "Who We Are",
      description: `At Rise Innovations, We invite people to come together.
Come to connect. Come to learn. Come to grow..`,
      xs: 12
    },
    {
      title: "Our Prospective",
      description: `Accelerating change in global aid and development.`,
      xs: 12
    },
    {
      title: "Our Tagline",
      description: "Devloping Solutions Impacting Lives",
      xs: 12
    },
    {
      title: "Contact Us",
      description: "Have any questions? Reach out to us!",
      xs: 12
    },
    {
      title: "Phone",
      description: "+91 9937372662",
      xs: 12
    },
    {
      title: "Email",
      description: "contactriseinnovations@gmail.com",
      xs: 12
    }
  ];

  return (
    <Box
    sx={{
      // backgroundImage: 'url(/assets/backgroundd.svg)',
      // backgroundPosition: { xs: 'top', sm: 'center', md: 'center' }, 
      // backgroundSize: { xs: 'contain', sm: 'contain', md: 'auto' },
      // backgroundRepeat: 'repeat-y',
      bgcolor:'#020226',
      width: '100%',
      
      overflow: 'hidden',
      boxSizing: 'border-box',
      padding: { xs: '10px', sm: '20px', md: '10px' },
    }}
  >
    <div style={{ width: isMobile ? "100%" : "100%",paddingBottom:'70px',paddingTop:'20px', overflowX: isMobile ? "hidden" : "auto", marginBottom: '-50px', }}>
     

        <Container maxWidth="md">
          <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', fontWeight: 'bold', color: '#1976d2' }}>
            Get in Touch
          </Typography>
          <Grid container spacing={4}>
            {contents.map((content, index) => (
              <Grid key={index} item xs={12}>
                <Box
                  sx={{
                    bgcolor:'#0A1562',
                    padding: '2rem',
                    textAlign: 'center',
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '1rem',
                    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)'
                    }
                  }}
                >
                  <Typography variant='h6' sx={{ mb: 1, fontWeight: 'bold', color: '#fff' }}>
                    {content.title}
                  </Typography>
                  <Typography variant='body1' sx={{ color: '#fff', textAlign: 'center' }}>
                    {content.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      
    </div>
    </Box>
  );
}

export default Contact;
