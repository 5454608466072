import { Container } from '@mui/material';


export const PrivacyPolicy = () => {
    return (
        <Container sx={{ py: 8 }} maxWidth="xl">
            <p>
                This privacy policy sets out how <b style={{ color: '#0a5c6b' }}>ISI (Inner Strength Institute)</b> uses and protects any
                information that you give <b style={{ color: '#0a5c6b' }}>ISI (Inner Strength Institute)</b> when you use this website.
            </p>
            <p><b style={{ color: '#0a5c6b' }}>ISI (Inner Strength Institute)</b> is committed to ensuring that your privacy is protected. Should
                we ask you to provide certain information by which you can be identified when using this
                website, then you can be assured that it will only be used in accordance with this privacy
                statement. <b style={{ color: '#0a5c6b' }}>ISI (Inner Strength Institute)</b> may change this policy from time to time by
                updating this page. You should check this page from time to time to ensure that you are
                happy with any changes.</p>
            <h2 style={{ color: '#d6702e' }}>What we collect</h2>
            <p>
                We may collect the following information:
            </p>
            <p style={{ marginInline: '20px' }}><b style={{ color: '#0a5c6b' }}>1. Name, contact information including mobile and email address</b></p>
            <p style={{ marginInline: '20px' }}><b style={{ color: '#0a5c6b' }}>2. Other information relevant to customer surveys and/or offers</b></p>

            <h2 style={{ color: '#d6702e' }}>What we do with the information we gather</h2>
            <p>
                We require this information to understand your needs and provide you with a better
                service, and in particular for the following reasons: Internal record keeping.
            </p>
            <p style={{ marginInline: '20px' }}><b style={{ color: '#0a5c6b' }}>1. We may use the information to improve our products and services.</b></p>
            <p style={{ marginInline: '20px' }}><b style={{ color: '#0a5c6b' }}>2. From time to time, we may also use your information to contact you for market research
                purposes. We may contact you by email, phone. We may use the information to customize
                the website according to your interests.</b></p>
            <p style={{ marginInline: '20px' }}><b style={{ color: '#0a5c6b' }}>3. We may periodically send promotional emails about new products, special offers or other
                information which we think you may find interesting using the email address which you
                have provided.</b></p>
            <h2 style={{ color: '#d6702e' }}>Security</h2>
            <p>
                We are committed to ensuring that your information is secure. In order to prevent
                unauthorized access or disclosure, we have put in place suitable physical, electronic and
                managerial procedures to safeguard and secure the information we collect online.
            </p>
            <h2 style={{ color: '#d6702e' }}>Links to other websites</h2>
            <p>
                Our website may contain links to other websites of interest. However, once you have used
                these links to leave our site, you should note that we do not have any control over that
                other website. Therefore, we cannot be responsible for the protection and privacy of any
                information which you provide whilst visiting such sites and such sites are not governed
                by this privacy statement. You should exercise caution and look at the privacy statement
                applicable to the website in question.
            </p>
            <h2 style={{ color: '#d6702e' }}>Controlling your personal information</h2>
            <p>
                You may choose to restrict the collection or use of your personal by writing to or emailing
                us at “contactinnerstrength@gmail.com” to indicate that you do not want the information
                to be used by anybody for direct marketing purposes We will not sell, distribute or lease
                your personal information to third parties under any circumstance. may request details of
                personal information which we hold about you under the Data Protection Act 1998.
            </p>
        </Container>
    );
};
