import "../index";
import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  Grid,
  CardContent,
  Card,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Main Component
function HomePage() {
  const navigate = useNavigate();

  // Create Refs for elements to animate
  const headerRef = useRef<HTMLDivElement | null>(null);
  const typographyRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const visionMissionRef = useRef<HTMLDivElement | null>(null);
  const focusRef = useRef<HTMLDivElement | null>(null);
  const joinUsRef = useRef<HTMLDivElement | null>(null);
  const platformsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // GSAP Animation for the header section
    gsap.from(headerRef.current, {
      opacity: 0,
      y: 50,
      duration: 1.5,
      ease: "power2.out",
    });

    // GSAP Animation for Typography (heading text)
    gsap.from(typographyRef.current, {
      opacity: 0,
      x: -50,
      duration: 1.5,
      delay: 0.5, 
      ease: "power2.out",
    });

    // GSAP Animation for Button
    gsap.from(buttonRef.current, {
      opacity: 0,
      y: 50,
      duration: 1.5,
      delay: 1,
      ease: "power2.out",
    });

    // Animate Vision and Mission Cards
    if (visionMissionRef.current) {
      gsap.fromTo(
        (visionMissionRef.current as HTMLDivElement).children,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.3,
          ease: "power2.out",
          scrollTrigger: {
            trigger: visionMissionRef.current,
            start: "top 75%",
            end: "bottom 25%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }

    // Animate Our Focus Section
    if (focusRef.current) {
      gsap.fromTo(
        (focusRef.current as HTMLDivElement).children,
        { opacity: 0, scale: 0.8 },
        {
          opacity: 1,
          scale: 1,
          duration: 1.2,
          stagger: 0.3,
          ease: "back.out(1.7)",
          scrollTrigger: {
            trigger: focusRef.current,
            start: "top 85%",
            end: "bottom 25%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }

    // Animate Supporting Platforms Section
    if (platformsRef.current) {
      gsap.fromTo(
        (platformsRef.current as HTMLDivElement).children,
        { opacity: 0, scale: 0.8 },
        {
          opacity: 1,
          scale: 1,
          duration: 1.2,
          stagger: 0.2,
          ease: "power2.out",
          scrollTrigger: {
            trigger: platformsRef.current,
            start: "top 85%",
            end: "bottom 25%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }

    // Animate Join Us Section
    if (joinUsRef.current) {
      gsap.fromTo(
        (joinUsRef.current as HTMLDivElement).children,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.3,
          ease: "power3.out",
          scrollTrigger: {
            trigger: joinUsRef.current,
            start: "top 85%",
            end: "bottom 25%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }
  }, []);

  const handleNavigate = () => {
    navigate("/contactus");
  };

  // Data Arrays
  const focusItems = [
    {
      title: "Empowering Youths",
      image: "/assets/courses/focus1.png",
      route: "/empowering-youths",
    },
    {
      title: "Facing Health Challenges",
      image: "/assets/courses/focus2.png",
      route: "/facing-health-challenges",
    },
    {
      title: "Empowering Communication & CSOs",
      image: "/assets/courses/focus3.png",
      route: "/empowering-communities-csos",
    },
  ];

  const platforms = [
    { name: "Daily Life", route: "/daily-life" },
    { name: "Voice World", route: "/voice-world" },
    { name: "Connect World", route: "/connect-world" },
  ];

  const joinItems = [
    { title: "Work With Us", image: "/assets/courses/work.png", route: "/work-with-us" },
    { title: "Join Our Team", image: "/assets/courses/join.png", route: "/join-our-team" },
  ];

  const contentItems = [
    {
      title: "Our Vision",
      description:
        "To foster a world where communities, youth, and underprivileged populations can leverage technology and innovation to become self-reliant and empowered.",
      backgroundColor: "#FFC107",
      color: "black",
    },
    {
      title: "Our Mission",
      description:
        "To develop and implement innovative online platforms and social programs that empower youths, solve community health challenges, and promote self-reliance in underprivileged communities.",
      backgroundColor: "#FF5722",
      color: "white",
    },
  ];

  // Reusable Styles
  const cardStyles = {
    mx: {xs: 2, md:5},
    minHeight: "350px",
    p: 4,
    pt: 0,
  };

  const titleStyles = {
    width: "fit-content",
    px: 1.5,
    py: 0.5,
    textAlign: "center",
    fontWeight: 600,
    fontSize: "2.2rem",
    bgcolor: "#0A1562",
    color: "white",
    borderRadius: "0 0 15px 15px",
    mb: 2,
    position: "relative",
    left: { xs: "50%", md: "50%" },
    transform: { xs: "translateX(-50%)", md: "translateX(-50%)" },
  };

  const descriptionStyles = {
    fontSize: "1.5rem",
    fontWeight: 400,
  };

  // Divider with Circles Component
  const DividerWithCircles = () => (
    <Box sx={{ display: "flex", alignItems: "center", bgcolor: "#020226" }}>
      <Box
        sx={{
          width: "15px",
          height: "15px",
          bgcolor: "#FCAF17",
          borderRadius: "50%",
        }}
      />
      <Box sx={{ flexGrow: 1, height: "5px", bgcolor: "#FCAF17" }} />
      <Box
        sx={{
          width: "15px",
          height: "15px",
          bgcolor: "#FCAF17",
          borderRadius: "50%",
        }}
      />
    </Box>
  );

  return (
    <Box sx={{ width: "100%", overflow: "hidden",backgroundColor: "#00274D" }}>
      {/* Header Section */}
      <Box
        ref={headerRef}
        sx={{
          backgroundColor: "#00274D",
          color: "white",
          textAlign: "center",
          py: 18,
          backgroundImage: "url(/assets/courses/heroSection.png)",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Typography
          variant="h3"
          ref={typographyRef}
          sx={{
            px: 5,
            fontWeight: "750",
            fontSize: { xs: "2rem", md: "4rem" },
            color: "#FCAF17",
            width: { xs: "100%", md: "55%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          Accelerating change in global aid and development.
        </Typography>
        <Button
          onClick={handleNavigate}
          ref={buttonRef}
          sx={{
            position: "absolute",
            left: { xs: "50%", md: "8%" },
            transform: { xs: "translateX(-50%)", md: "none" },
            mt: 3,
            textTransform: "capitalize",
            color: "white",
            backgroundColor: "#FC4C01",
            fontSize: "1.5rem",
            fontWeight: "750",
            borderRadius: "15px",
            ":hover": { backgroundColor: "#E04511" },
          }}
        >
          Contact Us
        </Button>
      </Box>

      {/* Vision and Mission Section */}
      <Box ref={visionMissionRef} sx={{ py: 5, backgroundColor: "#020226" }}>
        <Grid container spacing={2}>
          {contentItems.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  ...cardStyles,
                  backgroundColor: item.backgroundColor,
                  color: item.color,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ ...titleStyles, textAlign: "center" }}
                >
                  {item.title}
                </Typography>
                <Typography sx={descriptionStyles}>
                  {item.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Our Focus Section */}
      <DividerWithCircles />
      <Box
        ref={focusRef}
        sx={{ py: 5, textAlign: "center", backgroundColor: "#020226" }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", mb: 2, color: "#FCAF17", fontSize: "3rem" }}
        >
          OUR FOCUS
        </Typography>
        <Typography
          sx={{ mb: 4, fontSize: "2rem", fontWeight: "700", color: "white" }}
        >
          Social innovation programs, community empowerment, and technology
          solutions
        </Typography>

        <Grid container spacing={2}>
          {focusItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <CardActionArea
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ position: "relative", width: 400, height: 400 }}>
                  <CardMedia
                    component="img"
                    image={item.image}
                    alt={item.title}
                    sx={{ borderRadius: "50%", width: "100%", height: "100%" }}
                  />
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontSize: "2.5rem",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Link to={item.route}>
                    <Button
                      sx={{
                        position: "absolute",
                        bottom: 25,
                        left: "50%",
                        transform: "translateX(-50%)",
                        bgcolor: "#FCAF17",
                        color: "white",
                        fontWeight: "bold",
                        px: 1,
                        py: 0.5,
                        fontSize: "1.2rem",
                      }}
                    >
                      Learn More
                    </Button>
                  </Link>
                </Box>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Supporting Platforms Section */}
      <DividerWithCircles />
      <Box
        ref={platformsRef}
        sx={{ py: 5, textAlign: "center", backgroundColor: "#020226" }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", mb: 2, color: "#FCAF17", fontSize: "3rem" }}
        >
          Supporting Platforms
        </Typography>
        <Grid container spacing={2}>
          {platforms.map((platform, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  backgroundColor: "#FD4F132E",
                  color: "white",
                  p: 2,
                  mx: 2,
                  minHeight: "300px",                  
                }}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 800, fontSize: "1.5rem" }}
                  >
                    {platform.name}
                  </Typography>
                  <CardMedia
                    component="img"
                    src={`/assets/courses/${platform.name}.png`}
                    alt={`${platform.name} Logo`}
                    sx={{
                      height: "200px",
                      width: "100%",
                      my: 2,
                      objectFit: "contain",
                    }}
                  />
                  <Link to={platform.route}>
                    <Typography
                      sx={{
                        fontSize: "1.7rem",
                        textDecoration: "underline",
                        color: "#fff",
                      }}
                    >
                      Read More
                    </Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Join Us Section */}
      <DividerWithCircles />
      <Box
        ref={joinUsRef}
        sx={{ py: 5, textAlign: "center", backgroundColor: "#020226" }}
      >
        <Grid container spacing={2}>
          {joinItems.map((joinItem, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card sx={{ mx: 2 }}>
                <CardActionArea sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="400"
                    image={joinItem.image}
                    alt={joinItem.title}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0, 0, 0, 0.3)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "start",
                      p: 3,
                    }}
                  >
                    <Typography
                      color="white"
                      align="left"
                      sx={{
                        width: "50%",
                        fontWeight: "700",
                        fontSize: "4.2rem",
                        lineHeight: "1.2",
                        color: "#0A1562",
                        mb: 2,
                      }}
                    >
                      {joinItem.title}
                    </Typography>
                    <Link to={joinItem.route}>
                    <Button
                      sx={{
                        color: "white",
                        backgroundColor: "#FD4F13",
                        fontSize: "1.5rem",
                        fontWeight: "750",
                        borderRadius: "15px",
                        mt: 2,
                        ":hover": { backgroundColor: "#E04511" },
                      }}
                    >
                      Connect
                    </Button>
                    </Link>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default HomePage;
