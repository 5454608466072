import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Contact from "./pages/Contact";
import AuthLayout from "./components/shared/AuthLayout";
import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home";
import { Teams } from "./pages/Teams";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

// import NEWS from "./pages/NEWS";
// import { Products } from "./pages/Products";
// import { CoursesPage } from "./pages/Courses";
// import { Placements } from "./pages/Placements";
// import { ProductDetails } from "./pages/ProductDetails";
// import NewsDetails from "./NewsDetails";
// import MoreAboutNews from "./pages/MoreAboutNews";
// import Leadership from "./pages/Leadership";
// import Investors from "./pages/Investors";

import JavaFullStack from "./components/courses/JavaFullStack";
import MeanStack from "./components/courses/MeanStack";
import MernStack from "./components/courses/MernStack";
import BusinessAnalysis from "./components/courses/BusinessAnalysis";
import TestStack from "./components/courses/TestStack";
import PythonStack from "./components/courses/PythonStack";
import CyberSecurity from "./components/courses/CyberSecurity";
import GenerativeAi from "./components/courses/GenerativeAi";
import DevOps from "./components/courses/DevOps";
import UIUXDesign from "./components/courses/UiDesign";
import DailyLife from "./components/productCard/DailyLife";
import Sequfin from "./components/productCard/Sequfin";
import VoiceWorld from "./components/productCard/VoiceWorld";
import SupportWorld from "./components/productCard/SupportWorld";
import ConnectWorld from "./components/productCard/ConnectWorld";
import LearningWorld from "./components/productCard/LearningWorld";

import EmpoweringYouths from "./pages/EmpoweringYouths";
import HealthChallenges from "./pages/HealthChallenges";
import EmpoweringCommunities from "./pages/EmpoweringCommunities";
import WorkWithUs from "./pages/WorkWithUs";
import JoinOurTeam from "./pages/JoinOurTeam";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/team" element={<Teams />} />
          {/* <Route path="/News" element={<NEWS/>} /> */}
          {/* <Route path="/news/details/:category" element={<NewsDetails/>} /> */}
          {/* <Route path="/work" element={<Products />} /> */}
          {/* <Route path="/work/:id" element={<ProductDetails />} /> */}
          {/* <Route path="/courses" element={<CoursesPage />} /> */}
          {/* <Route path="/placements" element={<Placements />} /> */}

          {/* <Route path="/news/moreaboutnews" element={<MoreAboutNews />} /> */}
          {/* <Route path="/Leadership" element={<Leadership />} /> */}
          {/* <Route path="/Investors" element={<Investors />} /> */}

          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          
          <Route path="/empowering-youths" element={<EmpoweringYouths />} />
          <Route path="/facing-health-challenges" element={<HealthChallenges />} />
          <Route path="/empowering-communities-csos" element={<EmpoweringCommunities />} />

          <Route path="/work-with-us" element={<WorkWithUs />} />
          <Route path="/join-our-team" element={<JoinOurTeam />} />


          {/* Products Routings */}
          <Route path="/daily-life" element={<DailyLife />} />
          <Route path="/sequfin" element={<Sequfin />} />
          <Route path="/voice-world" element={<VoiceWorld />} />
          <Route path="/support-world" element={<SupportWorld />} />
          <Route path="/connect-world" element={<ConnectWorld />} />
          <Route path="/learning-world" element={<LearningWorld />} />

          {/* Courses Routings */}
          <Route path="/java-full-stack" element={<JavaFullStack />} />
          <Route path="/mean-stack" element={<MeanStack />} />
          <Route path="/mern-stack" element={<MernStack />} />
          <Route path="/business-analysis" element={<BusinessAnalysis />} />
          <Route path="/test-stack" element={<TestStack />} />
          <Route path="/python-stack" element={<PythonStack />} />
          <Route path="/cyber-security" element={<CyberSecurity />} />
          <Route path="/generative-ai" element={<GenerativeAi />} />
          <Route path="/devops" element={<DevOps />} />
          <Route path="/ui-ux-design" element={<UIUXDesign />} />

          {/* <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/profile" element={<ProfileDetails />} />
         
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/our-team" element={<OurTeam />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
