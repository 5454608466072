import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import { styled } from "@mui/system";

const SectionBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  color: "white",
  textAlign: "center",
});

const DividerWithCircles = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgcolor: "#020226",
    }}
  >
    <Box
      sx={{
        width: "12px",
        height: "12px",
        bgcolor: "#FFFFFF",
        borderRadius: "50%",
      }}
    />
    <Box sx={{ width: "95%", height: "2px", bgcolor: "#FFFFFF" }} />
    <Box
      sx={{
        width: "12px",
        height: "12px",
        bgcolor: "#FFFFFF",
        borderRadius: "50%",
      }}
    />
  </Box>
);

const DailyLifeDetails: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: "#020226",
        color: "#fff",
        fontFamily: "Arial, sans-serif",
        py: { xs: 2, md: 2 },
      }}
    >
      {/* Banner Section */}
      <Box
        sx={{
          bgcolor: "#D9D9D9",
          textAlign: "center",
          borderRadius: "20px",
          // py: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <CardMedia
            component="img"
            src="/assets/courses/DailyLife.png"
            alt="Daily Life Logo"
            sx={{ width: "140px" }}
          />
          <Box>
          <Typography
            variant="h3"
            sx={{ color: "#D6702E", fontSize: "5rem", fontWeight: 750, display:'inline', mr:1 }}
          >
            DAILY
          </Typography>
          <Typography
            variant="h3"
            sx={{ color: "#0A5C6A", fontSize: "5rem", fontWeight: 750, display:'inline'  }}
          >
            LIFE
          </Typography>
          </Box>
        </Box>
      </Box>

      {/* Register Prompt Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          my: 5,
          mx: 3,
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          src="/assets/courses/daily-mobile.png"
          alt="Job Search"
          sx={{
            width: { xs: "70%", md: "300px" },
            mx: "auto",
            mb: { xs: 3, md: 0 },
          }}
        />
        <Typography
          variant="h5"
          sx={{
            color: "#FF5722",
            fontSize: "3rem",
            fontWeight: "bold",
            mt: 3,
            width: { xs: "100%", md: "55%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          REGISTER NOW AND FIND YOUR WORKFORCE RIGHT AWAY!
        </Typography>
      </Box>

      <DividerWithCircles />

      {/* Who We Are Section */}
      <Box sx={{ textAlign: "center", p: { xs: 4, md: 8 }, color: "#fff" }}>
        <Typography
          variant="h4"
          sx={{
            color: "#fff",
            mb: 3,
            fontWeight: "700",
            fontSize: "3rem",
            WebkitTextStroke: "2px #FF5722",
          }}
        >
          Who we are?
        </Typography>
        <SectionBox
          sx={{
            px: { xs: 2, md: 8 },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: { xs: 0, md: 4 },
          }}
        >
          <Typography
            sx={{
              width: { xs: "100%", md: "60%" },
              fontSize: "1.4rem",
              fontWeight: 400,
              lineHeight: 1.6,
              textAlign: "left",
              mb: { xs: 3, md: 0 },
            }}
          >
            <ul style={{ listStyle: "none", paddingLeft: 0 }}>
              <li>
                Customers like Job providers use our platform to publish the
                work or opportunity for which they need the skilled workforce.
              </li>
              <br />
              <li>
                Similarly, the workforce who will execute the specified work can
                browse through the portal / mobile app relevant works to their
                proximity and accept and tag themselves to execute the task
                defined within constraints like budget and timeline.
              </li>
            </ul>
          </Typography>
          <CardMedia
            component="img"
            height="200"
            image="/assets/courses/job_search.png"
            alt="Youth Empowerment Event"
            sx={{ borderRadius: "15px", width: { xs: "100%", md: "40%" } }}
          />
        </SectionBox>
        <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: 400,
            textAlign: "left",
            px: { xs: 2, md: 8 },
          }}
        >
          Our platform empowers all types of workforce and is truly heading
          towards everything.
        </Typography>
      </Box>

      {/* Our Vision Section */}
      <Box
        sx={{
          bgcolor: "#B9735ABD",
          p: { xs: 4, md: 4 },
          color: "#fff",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          src="/assets/courses/vision.png"
          alt="Our Vision"
          sx={{
            width: { xs: "100%", md: "300px" },
            mx: "auto",
            mt: 4,
            mb: { xs: 3, md: 0 },
          }}
        />
        <Box sx={{ width: { xs: "100%", md: "60%" }, px: 3, my: 4 }}>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold", WebkitTextStroke: "2px #0A1562" }}>
            Our Vision:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              maxWidth: "700px",
              lineHeight: 1.4,
              fontSize: "1.5rem",
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            Empower and impact Billions of lives worldwide to ensure delivery
            services at your desired place and time with a best-in-class
            workforce and optimized cost which has never been experienced
            before.
          </Typography>
        </Box>
      </Box>

      {/* Register Section */}
      <Box sx={{ textAlign: "center", py: 4 }}>
        <CardMedia
          component="img"
          src="/assets/courses/daily-banner.png"
          alt="Our Vision"
          sx={{ width: "100%" }}
        />
      </Box>

      {/* App QR Code Section */}
      <Box
        sx={{
          mb: -2,
          bgcolor: "#FF5722",
          textAlign: "center",
          py: 3,
          px: 6,
          color: "#fff",
          width: "fit-content",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "30px 30px 0px 0px",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", mb: 2, WebkitTextStroke: "2px #0A1562"}}
        >
          For App Scan Here
        </Typography>
        <CardMedia
          component="img"
          src="/assets/courses/dailylife qrcode.png"
          alt="App QR Code"
          sx={{ width: "150px", mx: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default DailyLifeDetails;
