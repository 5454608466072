import React from "react";
import { Box, Typography, Grid, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CyberSecurity: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fontFamily = "'Poppins', sans-serif";
  const textStyle = {
    lineHeight: 1.8,
    fontSize: isSmallScreen ? "1.2rem" : "2rem",
    color: "#191919",
    fontFamily,
    mb: 2,
  };

  const imageStyle = {
    width: "70%",
    m: 4,
    borderRadius: "20px",
  };

  const DividerBar = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
      {Array.from({ length: 25 }, (_, i) => (
        <Box
          key={i}
          sx={{ height: 5, width: 30, bgcolor: "purple", borderRadius: 1 }}
        />
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: "#00274D",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        fontFamily: "'Poppins', sans-serif",
        p: 4,
      }}
    >
      {/* Watermark */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "90%",
          backgroundImage: "url('/assets/courses/cyber-logo.png')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.17,
          zIndex: 0,
        }}
      ></Box>

      {/* Content Area */}
      <Paper
        elevation={3}
        sx={{
          width: {xs:'90vw', md:'80vw'},
          padding: isSmallScreen ? "20px" : "30px",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          align="center"
          sx={{
            color: "#00337C",
            fontWeight: "bold",
            mb: 4,
            fontSize: isSmallScreen ? "2.5rem" : "3.5rem",
            fontStyle: "italic",
          }}
        >
          Cyber Security
        </Typography>

        <DividerBar />

        <Grid container spacing={4} alignItems="center">
          {/* Section 1 */}
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/cs1.jpg"
              alt="Cyber Security Tools"
              sx={imageStyle}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              A cybersecurity course aims to provide a thorough understanding of
              protecting systems and networks, along with practical skills to
              implement security measures effectively.
            </Typography>
          </Grid>

          {/* Section 2 */}
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              A cybersecurity course typically covers the fundamental principles
              and practices required to protect computer systems and networks
              from various threats.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/cs2.jpg"
              alt="Cyber Security Usability"
              sx={imageStyle}
            />
          </Grid>

          {/* Section 3 */}
          <Grid item xs={12} sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContents:'center'}}>
            <Box
              component="img"
              src="assets/courses/cs3.jpg"
              alt="Cyber Security Practical Projects"
              sx={imageStyle}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CyberSecurity;
