import React from "react";
import { Box, Typography, Grid, CardMedia } from "@mui/material";
import { styled } from "@mui/system";

// Custom styled components
const HeaderBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  textAlign: "center",
});

const SectionBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  color: "white",
  textAlign: "center",
});

const DividerWithCircles = () => (
  <Box
    sx={{
      m: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgcolor: "#020226",
    }}
  >
    <Box
      sx={{
        width: "12px",
        height: "12px",
        bgcolor: "#FCAF17",
        borderRadius: "50%",
      }}
    />
    <Box
      sx={{
        width: { xs: "60%", md: "40%" },
        height: "2px",
        bgcolor: "#FCAF17",
      }}
    />
    <Box
      sx={{
        width: "12px",
        height: "12px",
        bgcolor: "#FCAF17",
        borderRadius: "50%",
      }}
    />
  </Box>
);

const DoubleDividerWithCircles = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgcolor: "#020226",
    }}
  >
    <Box
      sx={{
        width: "15px",
        height: "15px",
        bgcolor: "#FFFFFF",
        borderRadius: "50%",
      }}
    />
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "90%", md: "96vw" },
      }}
    >
      <Box sx={{ width: "100%", height: "2px", bgcolor: "#FFFFFF", mb: 0.3 }} />
      <Box sx={{ width: "100%", height: "2px", bgcolor: "#FFFFFF" }} />
    </Box>
    <Box
      sx={{
        width: "15px",
        height: "15px",
        bgcolor: "#FFFFFF",
        borderRadius: "50%",
      }}
    />
  </Box>
);

const EmpoweringYouths: React.FC = () => {
  return (
    <Box>
      {/* Header Section */}
      <HeaderBox>
        <Typography
          variant="h4"
          sx={{
            width: "fit-content",
            px: 1.5,
            py: 1.5,
            borderRadius: "15px",
            color: "#0A1562",
            bgcolor: "#D9D9D9",
            fontWeight: "bold",
            fontSize: { xs: "2rem", md: "2.5rem" },
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            mb: 2,
          }}
        >
          Empowering Youths
        </Typography>
        <Typography
          sx={{
            color: "#FD4F13",
            fontWeight: 600,
            fontSize: { xs: "1.5rem", md: "2rem" },
          }}
        >
          Empowering Next (A) Generations (ENAG)
        </Typography>
        <DividerWithCircles />
      </HeaderBox>

      {/* Content Section */}
      <SectionBox
        sx={{
          px: { xs: 2, md: 8 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            width: { xs: "100%", md: "60%" },
            fontSize: "1.4rem",
            fontWeight: 400,
            lineHeight: 1.6,
            textAlign: "left",
            mb: { xs: 3, md: 0 },
          }}
        >
          <ul style={{ paddingLeft: 8 }}>
            <li>
              Targeting youths from (A) Africa, Asia, and the Arab worlds.
            </li>
            <li>
              Mobilizing local and international resources to provide youths
              with mentorship and real-world experience opportunities.
            </li>
            <li>
              Engages students and fresh graduates in projects within our
              initiatives and partner organizations.
            </li>
            <li>
              Program nurtures sustainable mentorship with mentees becoming
              future mentors, creating a cycle of empowerment.
            </li>
            <li>
              Pilots initiated in Kenya under the umbrella of Columbia
              University Alumni and Jamia Mosque (Kenya’s largest mosque).
            </li>
            <li>
              Specifically, targeting Qatari university students for real-world
              work experience in Africa and India.
            </li>
            <li>
              Students from technology fields will work in India, while those in
              social sciences, media, marketing, and business will focus on
              projects in Kenya.
            </li>
            <li>
              Students contribute remotely during school terms and work on-site
              during vacations.
            </li>
          </ul>
        </Typography>
        <CardMedia
          component="img"
          height="300"
          image="/assets/courses/youth1.png"
          alt="Youth Empowerment Event"
          sx={{
            borderRadius: "15px",
            width: { xs: "100%", md: "30%" },
            maxWidth: 400,
            mx: { xs: 0, md: 8 },
            my: { xs: 2, md: 0 },
          }}
        />
      </SectionBox>

      <DoubleDividerWithCircles />

      {/* Youths Camp Section */}
      <SectionBox>
        <Typography
          variant="h4"
          sx={{
            color: "#FD4F13",
            fontWeight: "bold",
            fontSize: { xs: "2rem", md: "2.5rem" },
            mb: 2,
          }}
        >
          Youths Camps
        </Typography>
        <DividerWithCircles />
        <SectionBox
          sx={{
            px: { xs: 2, md: 8 },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            height="300"
            image="/assets/courses/youth2.png"
            alt="Youth Empowerment Event"
            sx={{
              borderRadius: "15px",
              width: { xs: "100%", md: "30%" },
              maxWidth: 400,
              mx: { xs: 0, md: 8 },
              my: { xs: 2, md: 0 },
            }}
          />
          <Typography
            sx={{
              width: { xs: "100%", md: "60%" },
              fontSize: "1.4rem",
              fontWeight: 400,
              lineHeight: 1.6,
              textAlign: "left",
            }}
          >
            <ul style={{ paddingLeft: 8 }}>
              <li>
                Designed to nurture the next generation of humanitarian
                innovators.
              </li>
              <li>
                Aimed at youths aspiring to become experts in their fields and
                use their expertise to solve community challenges.
              </li>
              <li>
                Equips participants with problem-solving, leadership, and
                innovation skills tailored for social impact.
              </li>
            </ul>
          </Typography>
        </SectionBox>
        <Grid container sx={{ my: 5, justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CardMedia
              component="img"
              height="300"
              image="/assets/courses/youth3.png"
              alt="Camp Image 1"
              sx={{ borderRadius: "25px", maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
      </SectionBox>
    </Box>
  );
};

export default EmpoweringYouths;
