// WorkWithUsForm.tsx
import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';

const WorkWithUs: React.FC = () => {
  return (
    <Box sx={{bgcolor:'#020226', py:4}}>
        <Typography
        variant="h4"
        sx={{
          color: '#fff', // Dark blue color for the title
          marginBottom: 3,
          fontWeight: '700',
          fontSize:'3rem',
          textAlign:'center'
        }}
      >
        WORK WITH US
      </Typography>
    <Box
      sx={{
        backgroundColor: '#ff4500', // Orange background color
        padding: {xs:3,md:5},
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '85vw',
        margin: 'auto',
      }}
    >
      
      
      <TextField
        label="Name"
        variant="filled"
        sx={{
        width:{xs:'100%', md:'85%'},
          marginBottom: 2,
          backgroundColor: '#00043a', // Dark blue background for fields
          '& .MuiInputBase-input': { color: '#fff' }, // White input text
          '& .MuiInputLabel-root': { color: '#fff' }, // White label text
        }}
      />
      
      <TextField
        label="Subject"
        variant="filled"
        sx={{
          width:{xs:'100%', md:'85%'},
          marginBottom: 2,
          backgroundColor: '#00043a',
          '& .MuiInputBase-input': { color: '#fff' },
          '& .MuiInputLabel-root': { color: '#fff' },
        }}
      />
      
      <TextField
        label="Affiliated Organization"
        variant="filled"
        sx={{
          width:{xs:'100%', md:'85%'},
          marginBottom: 2,
          backgroundColor: '#00043a',
          '& .MuiInputBase-input': { color: '#fff' },
          '& .MuiInputLabel-root': { color: '#fff' },
        }}
      />
      
      <TextField
        label="Contact Email"
        variant="filled"
        sx={{
          width:{xs:'100%', md:'85%'},
          marginBottom: 2,
          backgroundColor: '#00043a',
          '& .MuiInputBase-input': { color: '#fff' },
          '& .MuiInputLabel-root': { color: '#fff' },
        }}
      />
      
      <TextField
        label="Contact Number"
        variant="filled"
        sx={{
          width:{xs:'100%', md:'85%'},
          marginBottom: 2,
          backgroundColor: '#00043a',
          '& .MuiInputBase-input': { color: '#fff' },
          '& .MuiInputLabel-root': { color: '#fff' },
        }}
      />
      
      <TextField
        label="Message"
        variant="filled"
        multiline
        rows={4}
        sx={{
          width:{xs:'100%', md:'85%'},
          marginBottom: 2,
          backgroundColor: '#00043a',
          '& .MuiInputBase-input': { color: '#fff' },
          '& .MuiInputLabel-root': { color: '#fff' },
        }}
      />
      
      <Button
        variant="contained"
        sx={{
          marginTop: 2,
          backgroundColor: '#00043a', // Dark blue for the button
          color: '#fff',
          fontWeight: '500',
          fontSize:'1.2rem',
          py:1,
          px:6,
          '&:hover': {
            backgroundColor: '#00043a',            
          },
        }}
      >
        SUBMIT
      </Button>
    </Box>
    </Box>
  );
};

export default WorkWithUs;
