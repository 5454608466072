import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Typography } from "@mui/material";
import gsap from "gsap";

const NavBar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElProducts, setAnchorElProducts] = useState<null | HTMLElement>(
    null
  );
  const [anchorElCourses, setAnchorElCourses] = useState<null | HTMLElement>(
    null
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();

  const logoRef = useRef<HTMLImageElement>(null);
  const menuItemsRef = useRef<(HTMLAnchorElement | null)[]>([]);

  useEffect(() => {
    // Animation for the logo
    gsap.from(logoRef.current, {
      opacity: 0,
      x: -150,
      duration: 1,
      ease: "power2.out",
    });

    // Animation for menu items (desktop version)
    if (!isMobile) {
      gsap.from(menuItemsRef.current, {
        opacity: 0,
        y: -30,
        stagger: 0.2,
        duration: 1.5,
        ease: "power2.out",
      });
    }
  }, [isMobile]);
  
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setDrawerOpen(false);
    setAnchorElProducts(null);
    setAnchorElCourses(null);
  };

  const linkStyles = (path: string) => ({
    marginRight: "20px",
    color: location.pathname === path ? "#FC4C01" : "#fff",
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      color: "#FC4C01",
      cursor:'pointer'
    },
  });

  // Dropdown handling
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  ) => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#0A1562",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <CardMedia
              onClick={() => handleNavigation("/")}
              component="img"
              alt="Rise Innovation logo"
              image="/assets/Rise innovation logo.svg"
              ref={logoRef}
              sx={{
                objectFit: "contain",
                width: { xs: "80px", md: "120px" },
                height: { xs: "50px", md: "90px" },
                mb: { xs: "0px", md: "0" },
              }}
            />
          </Box>

          {isMobile ? (
            <>           
              <IconButton              
                sx={{
                  color: "white",
                  marginRight: { xs: "10px", sm: "15px", md: "10px" },
                }}
              >
                <SearchIcon sx={{ color: "white", bgcolor: "#FC4C01", borderRadius:'50%' }} />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{
                  color: "white",
                  marginRight: { xs: "10px", sm: "15px", md: "10px" },
                }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                <List sx={{ width: 160 }}>
                  <ListItemButton onClick={() => handleNavigation("/")}>
                    <ListItemText
                      primary="Home"
                      sx={{
                        color:
                          location.pathname === "/" ? "#FC4C01" : "inherit",                         
                      }}
                    />
                  </ListItemButton>
                  {/* Mobile Dropdown for Products */}
                  <ListItemButton
                    onClick={(e) => handleMenuOpen(e, setAnchorElProducts)}
                  >
                    <ListItemText
                      primary="Products"
                      sx={{
                        color:
                          location.pathname === "/products"
                            ? "#FC4C01"
                            : "inherit",
                      }}
                    />
                  </ListItemButton>
                  <Menu
                    anchorEl={anchorElProducts}
                    open={Boolean(anchorElProducts)}
                    onClose={() => handleMenuClose(setAnchorElProducts)}
                  >
                    <MenuItem onClick={() => handleNavigation("daily-life")}>
                      Daily Life
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("sequfin")}>
                      Sequfin
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("voice-world")}>
                      Voice World
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("support-world")}>
                      Support World
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("connect-world")}>
                      Connect World
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleNavigation("learning-world")}
                    >
                      Learning World
                    </MenuItem>
                  </Menu>

                  {/* Mobile Dropdown for Courses */}
                  <ListItemButton
                    onClick={(e) => handleMenuOpen(e, setAnchorElCourses)}
                  >
                    <ListItemText
                      primary="Learning Offering"
                      sx={{
                        color:
                          location.pathname === "/courses"
                            ? "#FC4C01"
                            : "inherit",
                      }}
                    />
                  </ListItemButton>
                  <Menu
                    anchorEl={anchorElCourses}
                    open={Boolean(anchorElCourses)}
                    onClose={() => handleMenuClose(setAnchorElCourses)}
                  >
                    <MenuItem
                      onClick={() => handleNavigation("/java-full-stack")}
                    >
                      Java Full Stack
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("/mean-stack")}>
                      Mean stack
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("/mern-stack")}>
                      Mern Stack
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleNavigation("/business-analysis")}
                    >
                      Business Analysis
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("/test-stack")}>
                      Test Stack
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("/python-stack")}>
                      Python Stack
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleNavigation("/cyber-security")}
                    >
                      Cyber Security
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleNavigation("/generative-ai")}
                    >
                      Generative Artificial Intelligence
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("/devops")}>
                      DevOps Fundamentals
                    </MenuItem>
                    <MenuItem onClick={() => handleNavigation("/ui-ux-design")}>
                      Ui/Ux Design
                    </MenuItem>
                  </Menu>

                  <ListItemButton onClick={() => handleNavigation("/about")}>
                    <ListItemText
                      primary="About Us"
                      sx={{
                        color:
                          location.pathname === "/about"
                            ? "#FC4C01"
                            : "inherit",
                      }}
                    />
                  </ListItemButton>

                  <ListItemButton onClick={() => handleNavigation("/team")}>
                    <ListItemText
                      primary="Our Team"
                      sx={{
                        color:
                          location.pathname === "/team" ? "#FC4C01" : "inherit",
                      }}
                    />
                  </ListItemButton>

                  {/* News Section */}
                  {/* <ListItemButton
                    onClick={() => handleNavigation("/contactus")}
                  >
                    <ListItemText
                      primary="Contact"
                      sx={{
                        color:
                          location.pathname === "/contactus"
                            ? "#FC4C01"
                            : "inherit",
                      }}
                    />
                  </ListItemButton> */}

                  {/* News Section */}
                  {/* <ListItemButton onClick={() => handleNavigation("/news")}>
                    <ListItemText
                      primary="News"
                      sx={{
                        color:
                          location.pathname === "/news" ? "#FC4C01" : "inherit",
                      }}
                    />
                  </ListItemButton> */}

                  {/* Leadership Section */}
                  {/* <ListItemButton
                    onClick={() => handleNavigation("/leadership")}
                  >
                    <ListItemText
                      primary="Leadership"
                      sx={{
                        color:
                          location.pathname === "/leadership"
                            ? "#FC4C01"
                            : "inherit",
                      }}
                    />
                  </ListItemButton> */}

                  {/* Investor Section */}
                  {/* <ListItemButton
                    onClick={() => handleNavigation("/investors")}
                  >
                    <ListItemText
                      primary="Investors"
                      sx={{
                        color:
                          location.pathname === "/Investors"
                            ? "#FC4C01"
                            : "inherit",
                      }}
                    />
                  </ListItemButton> */}
                </List>
              </Drawer>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                justifyContent: "center",               
              }}
            >
              <Link onClick={() => handleNavigation("/")} sx={linkStyles("/")}
              ref={(el) => (menuItemsRef.current[0] = el)}>
                Home
              </Link>

              {/* Desktop Dropdown for Products */}
              <Link
                onClick={(e) => handleMenuOpen(e, setAnchorElProducts)}
                sx={linkStyles("/products")}
                ref={(el) => (menuItemsRef.current[1] = el)}
              >
                Our Works
              </Link>
              <Menu
                anchorEl={anchorElProducts}
                open={Boolean(anchorElProducts)}
                onClose={() => handleMenuClose(setAnchorElProducts)}
              >
                <MenuItem onClick={() => handleNavigation("daily-life")}>
                  Daily Life
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("sequfin")}>
                  Sequfin
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("voice-world")}>
                  Voice World
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("support-world")}>
                  Support World
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("connect-world")}>
                  Connect World
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("learning-world")}>
                  Learning World
                </MenuItem>
              </Menu>

              {/* Desktop Dropdown for Courses */}
              <Link
                onClick={(e) => handleMenuOpen(e, setAnchorElCourses)}
                sx={linkStyles("/courses")}
                ref={(el) => (menuItemsRef.current[2] = el)}
              >
                Learning Offering
              </Link>
              <Menu
                anchorEl={anchorElCourses}
                open={Boolean(anchorElCourses)}
                onClose={() => handleMenuClose(setAnchorElCourses)}
              >
                <MenuItem onClick={() => handleNavigation("/java-full-stack")}>
                  Java Full Stack
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/mean-stack")}>
                  Mean stack
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/mern-stack")}>
                  Mern Stack
                </MenuItem>
                <MenuItem
                  onClick={() => handleNavigation("/business-analysis")}
                >
                  Business Analysis
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/test-stack")}>
                  Test Stack
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/python-stack")}>
                  Python Stack
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/cyber-security")}>
                  Cyber Security
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/generative-ai")}>
                  Generative Artificial Intelligence
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/devops")}>
                  DevOps Fundamentals
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/ui-ux-design")}>
                  Ui/Ux Design
                </MenuItem>
              </Menu>

              <Link
                onClick={() => handleNavigation("/about")}
                sx={linkStyles("/about")}
                ref={(el) => (menuItemsRef.current[3] = el)}
              >
                About Us
              </Link>
              <Link
                onClick={() => handleNavigation("/team")}
                sx={linkStyles("/team")}
                ref={(el) => (menuItemsRef.current[4] = el)}
              >
                Our Team
              </Link>
              <Link
                onClick={() => handleNavigation("/")}
                ref={(el) => (menuItemsRef.current[5] = el)}>
              <IconButton sx={{ marginLeft: "20px" }} color="inherit">
                <SearchIcon sx={{ color: "white", bgcolor: "#FC4C01", borderRadius:'50%'  }} 
                />
              </IconButton>
</Link>
              <Button sx={{marginLeft: "10px"}}>
              <Link
                onClick={() => handleNavigation("/contactus")}
                sx={linkStyles("/contactus")}
                ref={(el) => (menuItemsRef.current[6] = el)}
              >
                <Typography sx={{ color:'#FC4C01', fontSize:'1.1rem'}}>Join Us</Typography>
              </Link>
              </Button>

              {/* <Link
                onClick={() => handleNavigation("/contactus")}
                sx={linkStyles("/contactus")}
              >
                Contact
              </Link>
              <Link
                onClick={() => handleNavigation("/news")}
                sx={linkStyles("/news")}
              >
                News
              </Link>
              <Link
                onClick={() => handleNavigation("/leadership")}
                sx={linkStyles("/leadership")}
              >
                Leadership
              </Link>
              <Link
                onClick={() => handleNavigation("/investors")}
                sx={linkStyles("/investors")}
              >
                Investors
              </Link> */}
            </Box>
          )}
          {/* <IconButton sx={{ marginLeft: "20px" }} color="inherit">
            <LanguageIcon sx={{ color: "white" }} />
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
