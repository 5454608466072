import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import { styled } from "@mui/system";

const SectionBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  color: "white",
  textAlign: "center",
});

const VoiceWorldDetails: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: "#020226",
        color: "#fff",
        fontFamily: "Arial, sans-serif",
        py: { xs: 2, md: 2 },
      }}
    >
      {/* Banner Section */}
      <Box
        sx={{
          bgcolor: "#D9D9D9",
          textAlign: "center",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <CardMedia
            component="img"
            src="/assets/courses/Voice World.png"
            alt="Daily Life Logo"
            sx={{ width: "140px" }}
          />
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: "#B366B8",
                fontSize: "4.5rem",
                fontWeight: 750,
                display: "inline",
                mr: 1,
              }}
            >
              Voice
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "#3173C1",
                fontSize: "4.5rem",
                fontWeight: 750,
                display: "inline",
              }}
            >
              World
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Register Prompt Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          // my: 5,
          mx: 3,
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <SectionBox
          sx={{
            px: { xs: 2, md: 8 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            image="/assets/courses/voice1.png"
            alt="Voice World"
            sx={{
              borderRadius: "15px",
              width: { xs: "100%", md: "40%" },
              mx: { xs: 0, md: 2 },
              my: { xs: 2, md: 0 },
              bgcolor: "#fff",
            }}
          />
          <Typography
            sx={{
              width: { xs: "100%", md: "90%" },
              fontSize: "1.4rem",
              fontWeight: 400,
              lineHeight: 1.6,
              textAlign: "left",
              mt: { xs: 0, md:3 },
            }}
          >
            <ul style={{ paddingLeft: 0, listStyle: "none" }}>
              <li>
                In today’s fast-paced world, connecting with others from
                anywhere in the world has never been more important. At
                Voiceworld we’re committed to making virtual communication
                seamless, effective, and enjoyable for everyone.
              </li>
            </ul>
          </Typography>
        </SectionBox>
      </Box>

      {/* Features Section */}

      <SectionBox
        sx={{
          px: { xs: 0, md: 18 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: { xs: 0, md: 8 },
          py:0
        }}
      >
        <CardMedia
          component="img"
          // height="200"
          image="/assets/courses/voice2.png"
          alt="Voice World"
          sx={{ borderRadius: "15px", width: { xs: "80%", md: "30%" } }}
        />
        <Typography
          sx={{
            width: { xs: "100%", md: "60%" },
            fontSize: "1.4rem",
            fontWeight: 400,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            <li>
              <Typography
                variant="h3"
                sx={{
                  width: { xs: "100%", md: "60%" },
                  fontSize: "2.2rem",
                  fontWeight: 700,
                  lineHeight: 1.6,
                  mb: { xs: 3, md: 0 },
                }}
              >
                Innovation:
              </Typography>
              We’re always exploring new technologies and approaches to keep our
              platform at the cutting edge.
            </li>
          </ul>
        </Typography>        
      </SectionBox>

      <SectionBox
        sx={{
          px: { xs: 0, md: 18 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: { xs: 0, md: 8 },
          py:0
        }}
      >
        <Typography
          sx={{
            width: { xs: "100%", md: "60%" },
            fontSize: "1.4rem",
            fontWeight: 400,
            lineHeight: 1.6,
            textAlign: { xs: "center", md: "left" },
            mb: { xs: 3, md: 0 },
          }}
        >
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            <li>
              <Typography
                variant="h3"
                sx={{
                  width: { xs: "100%", md: "60%" },
                  fontSize: "2.2rem",
                  fontWeight: 700,
                  lineHeight: 1.6,
                  // textAlign: "left",
                  mb: { xs: 3, md: 0 },
                }}
              >
                Customer-Centricity:
              </Typography>
              Your needs drive our development. We listen to your feedback and
              continuously improve our services.
            </li>
          </ul>
        </Typography>
        <CardMedia
          component="img"
          // height="200"
          image="/assets/courses/voice3.png"
          alt="Youth Empowerment Event"
          sx={{ borderRadius: "15px", width: { xs: "90%", md: "25%" } }}
        />
      </SectionBox>

      {/* Join Us Section */}
      <Box
        sx={{
          bgcolor: "#D7BEFE5C",
          mt: {xs:4, md:3},
          p: { xs: 4, md: 4 },
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection:'column'
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            WebkitTextStroke: "2px #0A1562",
            mb: { xs: 3, md: 4 },
          }}
        >
          Join Us on Our Journey
        </Typography>
        <Typography
          variant="h3"
          sx={{
            width: {xs:"90vw",md:"60vw"},
            textAlign: "center",
            lineHeight: 1.5,
            fontSize: "1.4rem",
            fontWeight: 300,
            // display: "flex",
            // gap: { xs: 8, md: 5 },
          }}
        >
          At Voiceworld we’re not just building a video meeting app—we’re
          shaping the future of communication. Whether you’re a small business,
          a large enterprise, or an individual looking to stay connected, we
          invite you to experience the difference with us.
        </Typography>

        <CardMedia
          component="img"
          src="/assets/courses/voice4.png"
          alt="Services"
          sx={{
            width: { xs: "100%", md: "300px" },
            mx: "auto",
            mb: { xs: 3, md: 0 },
            mt: { xs: 3, md: -8 },
            transform: { xs: "translateY(0%)", md: "translateY(50%)" },
          }}
        />

      </Box>

      {/* App QR Code Section */}
      <Box
        sx={{
          mb: -2,
          bgcolor: "#FF5722",
          textAlign: "center",
          py: 3,
          px: 6,
          color: "#fff",
          width: "fit-content",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "30px 30px 0px 0px",
          mt: { xs: 3, md: 16 },
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", mb: 2, WebkitTextStroke: "2px #0A1562" }}
        >
          For App Scan Here
        </Typography>
        <CardMedia
          component="img"
          src="/assets/courses/voiceworld qrcode.png"
          alt="App QR Code"
          sx={{ width: "150px", mx: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default VoiceWorldDetails;
