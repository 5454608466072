import React from "react";
import { Box, Typography, Grid, CardMedia } from "@mui/material";
import { styled } from "@mui/system";

// Custom styled components
const HeaderBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  textAlign: "center",
});

const SectionBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  color: "white",
  textAlign: "center",
});

const DividerWithCircles = () => (
  <Box
    sx={{
      m: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgcolor: "#020226",
    }}
  >
    <Box
      sx={{
        width: "12px",
        height: "12px",
        bgcolor: "#FCAF17",
        borderRadius: "50%",
      }}
    />
    <Box
      sx={{
        width: { xs: "60%", md: "40%" },
        height: "2px",
        bgcolor: "#FCAF17",
      }}
    />
    <Box
      sx={{
        width: "12px",
        height: "12px",
        bgcolor: "#FCAF17",
        borderRadius: "50%",
      }}
    />
  </Box>
);

const DoubleDividerWithCircles = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgcolor: "#020226",
    }}
  >
    <Box
      sx={{
        width: "15px",
        height: "15px",
        bgcolor: "#FFFFFF",
        borderRadius: "50%",
      }}
    />
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "90%", md: "96vw" },
      }}
    >
      <Box sx={{ width: "100%", height: "2px", bgcolor: "#FFFFFF", mb: 0.3 }} />
      <Box sx={{ width: "100%", height: "2px", bgcolor: "#FFFFFF" }} />
    </Box>
    <Box
      sx={{
        width: "15px",
        height: "15px",
        bgcolor: "#FFFFFF",
        borderRadius: "50%",
      }}
    />
  </Box>
);

const HealthChallenges: React.FC = () => {
  return (
    <Box>
      {/* Header Section */}
      <HeaderBox>
        <Typography
          variant="h4"
          sx={{
            width: "fit-content",
            px: 1.5,
            py: 1.5,
            borderRadius: "15px",
            color: "#0A1562",
            bgcolor: "#D9D9D9",
            fontWeight: "bold",
            fontSize: { xs: "2rem", md: "2.5rem" },
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            mb: 2,
          }}
        >
          Facing Health Challenges
        </Typography>
        <Typography
          sx={{
            color: "#FD4F13",
            fontWeight: 600,
            fontSize: { xs: "1.5rem", md: "2rem" },
          }}
        >
          Deploying Refurbished Medical Equipment's (RME)
        </Typography>
        <DividerWithCircles />
      </HeaderBox>

      {/* Content Section */}
      <SectionBox
        sx={{
          px: { xs: 2, md: 8 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            width: { xs: "100%", md: "60%" },
            fontSize: "1.4rem",
            fontWeight: 400,
            lineHeight: 1.6,
            textAlign: "left",
            mb: { xs: 3, md: 0 },
          }}
        >
          <ul style={{ paddingLeft: 8 }}>
            <li>
              Focused on bringing refurbished medical equipment's from developed
              countries and deploying them in underserved regions of developing
              countries.
            </li>
            <br />
            <li>
              Ensures communities receive life-saving medical technology at a
              fraction of the cost, improving healthcare accessibility.
            </li>
          </ul>
        </Typography>
        <CardMedia
          component="img"
          height="300"
          image="/assets/courses/health1.png"
          alt="health Empowerment Event"
          sx={{
            borderRadius: "15px",
            width: { xs: "100%", md: "30%" },
            maxWidth: 400,
            mx: { xs: 0, md: 8 },
            my: { xs: 2, md: 0 },
          }}
        />
      </SectionBox>

      <DoubleDividerWithCircles />

      {/* healths Camp Section */}
      <SectionBox>
        <Typography
          variant="h4"
          sx={{
            color: "#FD4F13",
            fontWeight: "bold",
            fontSize: { xs: "2rem", md: "2.5rem" },
            mb: 2,
          }}
        >
          Establishing Sustainable Medical Centers (SMC)
        </Typography>
        <DividerWithCircles />
        <SectionBox
          sx={{
            px: { xs: 2, md: 8 },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            height="300"
            image="/assets/courses/health2.png"
            alt="health Empowerment Event"
            sx={{
              borderRadius: "15px",
              width: { xs: "100%", md: "30%" },
              maxWidth: 400,
              mx: { xs: 0, md: 8 },
              my: { xs: 2, md: 0 },
            }}
          />
          <Typography
            sx={{
              width: { xs: "100%", md: "60%" },
              fontSize: "1.4rem",
              fontWeight: 400,
              lineHeight: 1.6,
              textAlign: "left",
            }}
          >
            <ul style={{ paddingLeft: 8 }}>
              <li>
                Developing medical centers that offer high-quality services with
                minimal financial burden on patients.
              </li>
              <br />
              <li>
                Optimizes healthcare delivery processes and utilizes resources
                such as national health insurance to offset costs.
              </li>
              <br />
              <li>
                Fairfield Medical Centre (pilot project) in Kenya specializes in
                dialysis and cancer treatment.
              </li>
            </ul>
          </Typography>
        </SectionBox>
        <DividerWithCircles />
        <Typography sx={{ color: "#fff", fontSize: "3rem", fontWeight: "700" }}>
          Watch Video
        </Typography>
        <Grid container sx={{ my: 5, justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CardMedia
              component="img"
              height="300"
              image="/assets/courses/health3.png"
              alt="Camp Image 1"
              sx={{ borderRadius: "25px", maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
      </SectionBox>
    </Box>
  );
};

export default HealthChallenges;
