import { Box, CardMedia, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

function Footer() {
  const footerRef = useRef<HTMLDivElement | null>(null);
  const linksRef = useRef<(HTMLSpanElement | null)[]>([]);
  const logoRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    // Footer container animation
    if (footerRef.current) {
      gsap.fromTo(
        footerRef.current,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",         
        }
      );
    }

    // Logo animation
    if (logoRef.current) {
      gsap.fromTo(
        logoRef.current,
        { opacity: 0, scale: 0.8, rotation: -10 },
        {
          opacity: 1,
          scale: 1,
          rotation: 0,
          duration: 1,
          delay: 0.2,
          ease: "back.out(1.7)",         
        }
      );
    }

    // Links animation
    linksRef.current.forEach((link, index) => {
      if (link) {
        gsap.fromTo(
          link,
          { opacity: 0, y: 30, scale: 0.9 },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            delay: index * 0.15,
            duration: 0.8,
            ease: "elastic.out(1, 0.6)",           
          }
        );
      }
    });
  }, []);

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        background: "#0A1562",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        p: "20px",
      }}
    >
      <Box
          ref={footerRef}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", sm: "space-around" },
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          gap: { xs: "15px", md: "41px" },
          py: "50px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CardMedia
            component="img"
            alt="Rise Innovation logo"
            image="/assets/Rise innovation logo.svg"
            ref={logoRef}
            sx={{
              objectFit: "contain",
              width: { xs: "120px", md: "160px" },
              mb: { xs: "0px", md: "0" },
            }}
          />
        </Box>

        {["MODERATION POLICY", "PRIVACY POLICY", "TERMS & CONDITIONS", "GIVE US FEEDBACK"].map(
          (text, index) => (
            <Typography
              ref={(el) => (linksRef.current[index] = el)}
              key={text}
              sx={{
                fontSize: { xs: "12px", md: "15px" },
                fontFamily: "Montserrat",
                fontWeight: "600",
                color: "rgba(223, 235, 244, 1)",
                transition: "color 0.3s, text-shadow 0.3s",
                "&:hover": {
                  color: "#FD4F13",
                  textShadow: "0px 0px 8px rgba(255, 215, 0, 0.8)",
                },
              }}
            >
              {text}
            </Typography>
          )
        )}
      </Box>

      <Typography
        sx={{
          fontWeight: "600",
          fontSize: { xs: "10px", md: "12px" },
          pb: "0px",
          mt: { xs: "15px", md: "20px" },
          color: "rgba(223, 235, 244, 1)",
        }}
      >
        &#169; 2024 - Rise Innovations
      </Typography>
    </Box>
  );
}

export default Footer;