import { Box, Container, Typography } from "@mui/material";
import { AboutPageTeams } from "./AboutPageTeams";

function AboutUs() {
  // const items = Array(6).fill({});
  return (
    <Box
      sx={{       
        bgcolor:'#020226',
        width: "100%",

        overflow: "hidden",
        boxSizing: "border-box",
        padding: { xs: "10px", sm: "20px", md: "0px" },
      }}
    >
      <Container maxWidth="xl" sx={{ px: { xs: "20px", md: "50px" } }}>
        {/* Section 1 */}
        <Box
          component="section"
          sx={{
            marginTop: "30px",
            height: { xs: "auto", md: "260px" },
            border: "2px dashed",
            borderColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mx: { xs: "0", md: "90px" },
            textAlign: "center",
            padding: { xs: "20px", md: "80px" },
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.01)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: "#FD4F13",
                fontSize: { xs: "2rem", md: "2.2rem", lg: "2.6rem" },
                fontWeight: "bold",
                fontFamily: "Montserrat",
              }}
            >
              The Rise Innovation’s Vision
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "1.5rem", md: "1.4rem", lg: "1.6rem" },
                fontWeight: "600",
                fontFamily: "Montserrat",
                color: "#fff",
                paddingTop: "25px",
              }}
            >
              We envision a world where communities have the
              <span style={{}}> autonomy to create their own future </span>
            </Typography>
          </Box>
        </Box>

        {/* Section 2 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            width="2xl"
            component="section"
            sx={{
              width: "100%",
              maxWidth: "1400px",
              marginTop: "30px",
              height: { xs: "auto", md: "550px" },
              border: "2px solid #2E0A57",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: "url(assets/istockpho.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              borderRadius: { xs: "20px", md: "60px" },
              boxShadow: 0,
              opacity: 1,
              flexDirection: { xs: "column", md: "row" },
              textAlign: "left",
              overflow: "hidden",
              padding: { xs: "10px", md: "0" },
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.01)",
              },
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                zIndex: 1,
              },
              "& h2": {
                position: "relative",
                zIndex: 2,
                color: "white",
                fontWeight: "400",
                fontSize: { xs: "1.2rem", md: "1.4rem", lg: "1.6rem" },
                px: { xs: "20px", md: "100px" },
                my: "30px",
                fontFamily: "Montserrat",
                lineHeight: "1.5",
                paddingLeft: { xs: "20px", md: "70px" },
              },
            }}
          >
            <h2 style={{ hyphens: "auto", textAlign: "justify" }}>
              Rise Innovation is a venture. As such, we subscribe to its vision
              of a world where people thrive through their own resourcefulness
              and tenacity. In that world, communities have the autonomy to
              create their own future. It is a world where funders and
              organizations have shared goals and mutual respect. Where funders
              evolve how they see and work with organizations, and where those
              organizations have more independence. Where all of their efforts
              are guided by the lived experience of communities. Where funding,
              decision-making, and the balance of power are shifted into the
              hands of those closest to the communities. At Rise Innovation, we
              are working to make that world a reality.
            </h2>
          </Box>
        </Box>
      </Container>

      <Box
        sx={{
          marginTop: "40px",
          height: { xs: "auto", md: "auto", xl: "auto" },
          borderRadius: { xs: "20px", md: "50px" },
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "2px 4px 18px rgba(128, 128, 128, 0.8)",
          overflow: "hidden",
          position: "relative",
          background: "rgba(232, 245, 255, 1)",

          "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(232, 245, 255, 1)",
            opacity: 0.12,
            zIndex: 1,
          },
        }}
      >
        {/* Section 1 */}
        <Box sx={{ width: "100%" }}>
          <Box
            component="section"
            sx={{
              marginTop: { xs: "0px", md: "0px" },
              height: { xs: "auto", md: "auto", lg: "auto" },
              border: "3px solid",
              borderColor: "#014573",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mx: { xs: "20px", md: "250px" },
              background: "rgba(232, 245, 255, 1)",
              borderRadius: "24px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              borderTop: "none",
              textAlign: "center",
              padding: { xs: "20px", md: "60px" },
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.01)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.01)",
                },
              }}
            >
              <Typography
                component="h2"
                sx={{
                  color: "#014573",
                  fontSize: { xs: "1.6rem", md: "2rem", lg: "2rem" },
                  fontWeight: "bold",
                  fontFamily: "Montserrat",
                  pt: { xs: "10px", md: "0" },
                }}
              >
                We are on a Mission to:
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: "1.2rem", md: "1rem", lg: "1.4rem" },
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  color: "#1E8BF1",
                  paddingTop: "25px",
                  hyphens: "auto",
                  textAlign: "justify",
                }}
              >
                Equip and connect impact-driven organizations to accelerate
                change in their communities.
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Section 2 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            px: { xs: "20px", md: "80px" },
            py: "40px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              color: "#18252E",
              fontSize: { xs: "1rem", md: "1.6rem" },
              fontWeight: "400",
              fontFamily: "Montserrat",
              pt: { xs: "5px", md: "30px" },
              hyphens: "auto",
              textAlign: "justify",
            }}
          >
            The role we play is to equip and connect impact-driven organizations
            to accelerate change in their communities.
          </Typography>

          <Typography
            sx={{
              color: "#18252E",
              fontSize: { xs: "1rem", md: "1.6rem" },
              fontWeight: "400",
              fontFamily: "Montserrat",
              pt: "30px",
              hyphens: "auto",
              textAlign: "justify",
            }}
          >
            Leveraging our first-hand experience, we deliver trusted, practical
            solutions to funders and organizations, improving the relationships
            between them, boosting their capabilities and confidence, building
            their skills and strengths, and making the aid system more
            effective.
          </Typography>

          <Typography
            sx={{
              color: "#18252E",
              fontSize: { xs: "1rem", md: "1.6rem" },
              fontWeight: "400",
              fontFamily: "Montserrat",
              pt: "30px",
              pb: { xs: '20px', md: '50px' },
              hyphens: "auto",
              textAlign: "justify",
            }}
          >
            Ultimately, what we’re called reflects our calling: Rise Innovation.
            It is an invitation, a beckoning, to bring people, communities, and
            organizations closer together.
          </Typography>
        </Box>
      </Box>
      <AboutPageTeams />
    </Box>
  );
}

export default AboutUs;
