import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';


function AuthLayout(props: any) {
    return (
        <>
            <NavBar></NavBar>
            <Outlet></Outlet>
            <Footer></Footer>
        </>
    );
}

export default AuthLayout;