import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const GenerativeAi: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fontFamily = "'Poppins', sans-serif";
  const textStyle = {
    lineHeight: 1.8,
    fontSize: isSmallScreen ? "1.2rem" : "2rem",
    color: "#191919",
    fontFamily,
    mb: 2,
  };

  const imageStyle = { width: "100%",
    borderRadius: "20px", };

  const DividerBar = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
      {Array.from({ length: 25 }, (_, i) => (
        <Box
          key={i}
          sx={{ height: 5, width: 30, bgcolor: "purple", borderRadius: 1 }}
        />
      ))}
    </Box>
  );

  return (
    <Box    
    sx={{
      backgroundColor: "#00274D",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      fontFamily: "'Poppins', sans-serif",
      p:4
    }}
  >
      {/* Content Area */}
      <Paper
        elevation={3}
        sx={{
          width: {xs:'90vw', md:'80vw'},
          padding: isSmallScreen ? "20px" : "30px",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          align="center"
          sx={{
            color: "#00337C",
            fontWeight: "bold",
            mb: 4,
            fontSize: isSmallScreen ? "2.5rem" : "3.5rem",
            fontStyle: "italic",
          }}
        >
          Generative Artificial Intelligence
        </Typography>

        <DividerBar />

        <Grid container spacing={4} alignItems="center">
          {/* Section 1 */}
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              A Generative Artificial Intelligence course provides foundational
              knowledge in creating and working with AI models that generate new
              data.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/gen1.jpg"
              alt="Generative AI Elements"
              sx={imageStyle}
            />
          </Grid>

          {/* Section 2 */}
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/gen2.jpg"
              alt="Generative AI Tools"
              sx={imageStyle}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              It covers key generative models like GANs and VAEs, including
              their architectures and training processes.
            </Typography>
          </Grid>

          {/* Section 3 */}
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              The course includes hands-on projects for implementing these
              models using frameworks like TensorFlow or PyTorch.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/gen3.jpg"
              alt="Generative AI Usability"
              sx={imageStyle}
            />
          </Grid>

          {/* Section 4 */}
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/gen4.jpg"
              alt="Practical AI Projects"
              sx={imageStyle}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              Additionally, it addresses evaluation techniques, practical
              applications, and ethical considerations to ensure responsible use
              of generative AI technologies.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default GenerativeAi;
