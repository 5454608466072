import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const TestStack: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Define shared styles
  const sectionStyle = {
    fontFamily: "'Poppins', sans-serif",
    fontSize: isSmallScreen ? "1.2rem" : "2rem",
    color: "#191919",
    lineHeight: 1.8,
    padding: isSmallScreen ? "10px" : "20px",
    marginBottom: 6,
  };

  // Divider component for consistency
  const DividerBar = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
      {Array.from({ length: 25 }, (_, i) => (
        <Box
          key={i}
          sx={{ height: 5, width: 30, bgcolor: "purple", borderRadius: 1 }}
        />
      ))}
    </Box>
  );

  return (
    <Box    
    sx={{
      backgroundColor: "#00274D",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      fontFamily: "'Poppins', sans-serif",
      p:4
    }}
  >
      {/* Watermark */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "90%",
          backgroundImage: "url('/assets/courses/test-logo.png')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.17,
          zIndex: 0,
        }}
      >
      </Box>

      <Paper
        elevation={3}
        sx={{
          width: {xs:'90vw', md:'80vw'},
          padding: isSmallScreen ? "20px" : "30px",
          backgroundColor: "#fff",
          borderRadius:'20px'
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          align="center"
          sx={{
            color: "#00337C",
            fontWeight: "bold",
            mb: 4,
            fontStyle: "italic",
            fontSize: isSmallScreen ? "2.5rem" : "3.5rem",
          }}
        >
          Test Stack
        </Typography>        
        <DividerBar />
        
        <Grid container spacing={6} alignItems="center">
        <Grid item xs={12} sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContents:'center'}}>
            <Box
              component="img"
              src="assets/courses/test1.jpg"
              alt="Test Stack Overview"
              sx={{ width: "70%", m:4, borderRadius:'20px' }}
            />
            <Typography variant="body1" sx={sectionStyle}>
              The course aims to provide a comprehensive understanding of the
              entire testing process, from writing and automating tests to
              integrating them into a continuous development workflow.
            </Typography>
            <Box
              component="img"
              src="assets/courses/test2.jpg"
              alt="Testing Process Illustration"
              sx={{ width: "70%", m:4, borderRadius:'20px' }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default TestStack;
