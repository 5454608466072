import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const BusinessAnalysis: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fontFamily = "'Poppins', sans-serif";
  const textStyle = {
    lineHeight: 1.8,
    fontSize: isSmallScreen ? "1.2rem" : "2rem",
    color: "#191919",
    fontFamily,
  };

  const DividerBar = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
      {Array.from({ length: 25 }, (_, i) => (
        <Box
          key={i}
          sx={{ height: 5, width: 30, bgcolor: "purple", borderRadius: 1 }}
        />
      ))}
    </Box>
  );

  const imageStyle = {
    width: "100%",
    borderRadius: "20px",
  };

  return (
    <Box
    sx={{
      backgroundColor: "#00274D",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      fontFamily: "'Poppins', sans-serif",
      p:4
    }}
  >
      {/* Watermark */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "90%",
          backgroundImage: "url('/assets/courses/business-logo.png')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.17,
          zIndex: 0,
        }}
      >
      </Box>

      {/* Content Area */}
      <Paper
        elevation={3}
        sx={{
          width: {xs:'90vw', md:'80vw'},
          padding: isSmallScreen ? "20px" : "30px",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          align="center"
          sx={{
            color: "#00337C",
            fontWeight: "bolder",
            mb: 4,
            fontSize: isSmallScreen ? "2.5rem" : "3.5rem", // Adjust font size for title
            fontFamily,
            fontStyle: "italic",
          }}
        >
          Business Analysis
        </Typography>

        <DividerBar />

        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/Ba1.jpg"
              alt="UI/UX Design Tools"
              sx={imageStyle}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              Business Analysis basics – learn what a Business Analyst is, what
              they do, and how they do it. A breakdown of six project
              methodologies including traditional Waterfall and Agile
              frameworks.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              Learn how to properly initiate a project by creating a business
              case that aligns with the business objectives. Understand the
              basics of project requirements and six of the popular techniques
              used to elicit those details from your stakeholders. Gain an
              overview of various modeling diagrams to help you recognize and
              understand project documentation.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/Ba2.jpg"
              alt="UI/UX Usability"
              sx={imageStyle}
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/Ba3.jpg"
              alt="Practical Projects"
              sx={imageStyle}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={textStyle}>
              Conduct requirement specification - including categorizing,
              deriving (breaking apart), prioritizing, and validating. Have the
              confidence to successfully facilitate requirements approval
              meetings.
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box
              component="img"
              src="assets/courses/Ba4.jpg"
              alt="UI/UX Elements"
              sx={imageStyle}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default BusinessAnalysis;
