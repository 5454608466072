import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PythonStack: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Define reusable styles
  const fontFamily = "'Poppins', sans-serif";
  const sectionStyle = {
    fontFamily,
    fontSize: isSmallScreen ? "1.2rem" : "2rem",
    color: "#191919",
    lineHeight: 1.8,
    padding: isSmallScreen ? "10px" : "20px",
    mb: 6,
  };

  const imageStyle = {
    width: "70%",
    m: 4,
    borderRadius: "20px",
  };

  // Divider component for consistency
  const DividerBar = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
      {Array.from({ length: 25 }, (_, i) => (
        <Box
          key={i}
          sx={{ height: 5, width: 30, bgcolor: "purple", borderRadius: 1 }}
        />
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: "#00274D",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        fontFamily: "'Poppins', sans-serif",
        p: 4,
      }}
    >
      {/* Watermark */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "90%",
          backgroundImage: "url('/assets/courses/python-logo.png')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.17,
          zIndex: 0,
        }}
      ></Box>

      {/* Content Area */}
      <Paper
        elevation={3}
        sx={{
          width: {xs:'90vw', md:'80vw'},
          padding: isSmallScreen ? "20px" : "30px",
          backgroundColor: "#fff",
          borderRadius:'20px'
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          align="center"
          sx={{
            color: "#00337C",
            fontWeight: "bold",
            mb: 4,
            fontStyle: "italic",
            fontSize: isSmallScreen ? "2.5rem" : "3.5rem",
          }}
        >
          Python Stack
        </Typography>

        <DividerBar />

        <Grid container spacing={4} alignItems="center">
          {/* Section 1 */}
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src="assets/courses/py1.jpg"
              alt="Python Tools"
              sx={imageStyle}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body1" sx={{ ...sectionStyle, mb: 2 }}>
              A Python Full Stack course typically covers the development of
              both the front-end and back-end of web applications using Python
              and associated technologies.
            </Typography>
          </Grid>

          {/* Section 2 */}
          <Grid item xs={12} sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContents:'center'}}>
            <Box
              component="img"
              src="assets/courses/py2.jpg"
              alt="Python Full Stack"
              sx={imageStyle}
            />
            <Typography variant="body1" sx={sectionStyle}>
              The course is designed to provide a comprehensive understanding of
              building and maintaining a web application from end to end using
              Python and related technologies.
            </Typography>
            <Box
              component="img"
              src="assets/courses/py3.jpg"
              alt="Python Practical Projects"
              sx={imageStyle}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default PythonStack;
