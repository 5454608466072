import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import { styled } from "@mui/system";

// Define the props for the Section component
interface SectionProps {
  title: string;
  imageSrc: string;
  content: React.ReactNode;
}

// Styled components
const SectionBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  color: "white",
  textAlign: "center",
});

const CircleDivider = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#020226",
  "& .circle": {
    width: 12,
    height: 12,
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
  },
  "& .line": {
    width: "95%",
    height: 2,
    backgroundColor: "#FFFFFF",
  },
}));

const DividerWithCircles = () => (
  <CircleDivider>
    <Box className="circle" />
    <Box className="line" />
    <Box className="circle" />
  </CircleDivider>
);

const Banner = () => (
  <Box
    sx={{
      bgcolor: "#D9D9D9",
      textAlign: "center",
      borderRadius: "20px",
      py: 2,
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <CardMedia
        component="img"
        src="/assets/courses/sequfin logo.png"
        alt="Sequfin Logo"
        sx={{ width: "140px" }}
      />
      <Typography
        variant="h3"
        sx={{ color: "#5B00E8", fontSize: "5rem", fontWeight: 750 }}
      >
        Sequfin
      </Typography>
    </Box>
  </Box>
);

const Section: React.FC<SectionProps> = ({ title, imageSrc, content }) => (
  <Box sx={{ textAlign: "center", p: { xs: 4, md: 8 }, color: "#fff" }}>
    <Typography
      sx={{
        fontSize: "1.4rem",
        fontWeight: 400,
        textAlign: "left",
        px: { xs: 2, md: 9 },
      }}
    >
      {title}
    </Typography>
    <SectionBox
      sx={{
        px: { xs: 2, md: 8 },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        gap: { xs: 0, md: 4 },
      }}
    >
      <Typography
        sx={{
          width: { xs: "100%", md: "60%" },
          fontSize: "1.4rem",
          fontWeight: 400,
          lineHeight: 1.6,
          textAlign: "left",
          mb: { xs: 3, md: 0 },
        }}
      >
        <ul style={{ listStyle: "none", paddingLeft: 0 }}>{content}</ul>
      </Typography>
      <CardMedia
        component="img"
        height="200"
        image={imageSrc}
        alt="Section Image"
        sx={{ borderRadius: "15px", width: { xs: "100%", md: "50%" } }}
      />
    </SectionBox>
  </Box>
);

const SequfinDetails: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: "#020226",
        color: "#fff",
        fontFamily: "Arial, sans-serif",
        py: { xs: 2, md: 2 },
      }}
    >
      {/* Banner Section */}
      <Banner />

      {/* Register Prompt Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          my: 5,
          mx: 3,
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          src="/assets/courses/sequfin-mobile.png"
          alt="Job Search"
          sx={{
            width: { xs: "70%", md: "300px" },
            mx: "auto",
            mb: { xs: 3, md: 0 },
          }}
        />
        <Typography
          variant="h5"
          sx={{
            color: "#FF5722",
            fontSize: "3rem",
            fontWeight: "bold",
            mt: 3,
            width: { xs: "100%", md: "55%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          Secure Finance
        </Typography>
      </Box>

      <DividerWithCircles />

      {/* Who We Are Section */}
      <Section
        title="First-of-a-kind product designed to bring customers and financial merchants together ensuring customer's personal information isn't compromised in any form."
        imageSrc="/assets/courses/sequfin secure.png"
        content={
          <>
            <li>
              The user journey is designed so meticulously that customers only
              disclose their information at the point of service.
            </li>
            <br />
            <li>
              We have brought assisted service with experienced analysts to
              provide a financial solution that provides optimized value for
              money.
            </li>
          </>
        }
      />

      {/* Services Provided */}
      <Box sx={{ bgcolor: "#D7BEFE5C", p: { xs: 4, md: 4 }, color: "#fff" }}>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            WebkitTextStroke: "2px #0A1562",
            mb: { xs: 3, md: 0 },
          }}
        >
          Service Provided by Sequfin
        </Typography>

        <Box
          sx={{
            width: { xs: "100%", md: "80%" },
            px: 3,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            src="/assets/courses/sequfin lock.png"
            alt="Services"
            sx={{
              width: { xs: "100%", md: "300px" },
              mx: "auto",
              mb: { xs: 3, md: 0 },
              transform: { xs: "translateY(0%)", md: "translateY(50%)" },
            }}
          />
          <Typography
            variant="body1"
            sx={{
              maxWidth: "700px",
              lineHeight: 2,
              fontSize: "2rem",
              fontWeight: 400,
              display: "flex",
              gap: { xs: 8, md: 5 },
            }}
          >
            <ul>
              <li>Loans</li>
              <li>Tools</li>
            </ul>
            <ul>
              <li>Investment</li>
              <li>Insurance</li>
            </ul>
          </Typography>
        </Box>
      </Box>

      {/* Register Section */}
      <Box sx={{ textAlign: "center", py: 4 }}>
        <CardMedia
          component="img"
          src="/assets/courses/sequfin loan.png"
          alt="Register Section"
          sx={{
            width: { xs: "100%", md: "50%" },
            mx: "auto",
            mb: 2,
            transform: { xs: "translateX(0%)", md: "translateX(40%)" },
          }}
        />
      </Box>

      {/* App QR Code Section */}
      <Box
        sx={{
          mb: -2,
          bgcolor: "#FF5722",
          textAlign: "center",
          py: 3,
          px: 6,
          color: "#fff",
          width: "fit-content",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "30px 30px 0px 0px",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", mb: 2, WebkitTextStroke: "2px #0A1562" }}
        >
          For App Scan Here
        </Typography>
        <CardMedia
          component="img"
          src="/assets/courses/sequfin qrcode.png"
          alt="App QR Code"
          sx={{ width: "150px", mx: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default SequfinDetails;
