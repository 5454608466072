import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import { styled } from "@mui/system";

const SectionBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  color: "white",
  textAlign: "center",
});

const LearningWorldDetails: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: "#020226",
        color: "#fff",
        fontFamily: "Arial, sans-serif",
        py: { xs: 2, md: 2 },
      }}
    >
      {/* Banner Section */}
      <Box
        sx={{
          bgcolor: "#D9D9D9",
          textAlign: "center",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <CardMedia
            component="img"
            src="/assets/courses/learningWorld-logo.svg"
            alt="Daily Life Logo"
            sx={{ width: "70px" }}
          />
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: "#2E0A57",
                fontSize: { xs: "3.5rem", md: "4.5rem" },
                fontWeight: 750,
                display: "inline",
                mr: 1,
              }}
            >
              Learning
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "#2E0A57",
                fontSize: { xs: "3.5rem", md: "4.5rem" },
                fontWeight: 750,
                display: "inline",
              }}
            >
              World
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Register Prompt Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          // my: 5,
          mx: 3,
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <SectionBox
          sx={{
            px: { xs: 2, md: 8 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            image="/assets/courses/Learning1.png"
            alt="Learning World"
            sx={{
              borderRadius: "15px",
              width: { xs: "100%", md: "40%" },
              mx: { xs: 0, md: 2 },
              my: { xs: 2, md: 0 },
              bgcolor: "#fff",
            }}
          />
          <Typography
            sx={{
              width: { xs: "100%", md: "90%" },
              fontSize: "1.4rem",
              fontWeight: 400,
              lineHeight: 1.6,
              textAlign: "left",
              mt: { xs: 0, md: 3 },
            }}
          >
            <ul style={{ paddingLeft: 0, listStyle: "none" }}>
              <li>
                Online learning opportunities that promote the mindsets, skills,
                and practices required to create change.
              </li>
            </ul>
          </Typography>
        </SectionBox>
      </Box>

      {/* Features Section */}

      <SectionBox
        sx={{
          px: { xs: 0, md: 18 },
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: "center",
          gap: { xs: 0, md: 8 },
          py: 0,
        }}
      >
        <Typography
          sx={{
            width: { xs: "100%", md: "60%" },
            fontSize: "1.4rem",
            fontWeight: 400,
            textAlign: "left",
            mt: { xs: 3, md: 0 },
            px:{xs:8, md:2},
            
          }}
        >
          <ul style={{ paddingLeft: 0, listStyle:'disc'}}>
            <li>Compete with Best Minds.</li>
            <br />
            <li>Diverse leveled courses and minds under the same roof.</li>
            <br />
            <li>
              Learn from the one who has a deep realization of the subject.
            </li>
          </ul>
        </Typography>
        <CardMedia
          component="img"
          // height="200"
          image="/assets/courses/Learning2.png"
          alt="Learning World"
          sx={{ borderRadius: "15px", width: { xs: "80%", md: "30%" } }}
        />
      </SectionBox>

      <SectionBox
        sx={{
          px: { xs: 0, md: 18 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 0, md: 8 },
          py: 0,
        }}
      >
        <CardMedia
          component="img"
          // height="200"
          image="/assets/courses/Learning3.png"
          alt="Youth Empowerment Event"
          sx={{ borderRadius: "15px", width: { xs: "90%", md: "25%" } }}
        />
        <Typography
          sx={{
            width: "100%",
            fontSize: "1.4rem",
            fontWeight: 400,
            lineHeight: 1.6,
            textAlign: { xs: "center", md: "left" },
            mb: { xs: 3, md: 0 },
            mt: { xs: 3, md: 0 },
          }}
        >
          <ul style={{ listStyle: "none", paddingLeft: 0 , textAlign:'center'}}>
            <li>Connect and Collaborate with global Peers</li>
            <li>Learn from diverse-level best buddies</li>
          </ul>
        </Typography>
      </SectionBox>

      {/* App QR Code Section */}
      <Box
        sx={{
          mb: -2,
          bgcolor: "#FF5722",
          textAlign: "center",
          py: 3,
          px: 6,
          color: "#fff",
          width: "fit-content",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "30px 30px 0px 0px",
          mt: { xs: 3, md: 8 },
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", mb: 2, WebkitTextStroke: "2px #0A1562" }}
        >
          For App Scan Here
        </Typography>
        <CardMedia
          component="img"
          src="/assets/courses/sequfin qrcode.png"
          alt="App QR Code"
          sx={{ width: "150px", mx: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default LearningWorldDetails;
