import { Box, CardMedia, Typography } from '@mui/material';

export const Teams = () => {
  
  const teamMembers = [
    {
      photo: '/assets/teams/manas.png',
      name: 'Manas Kumar Nandi',
      title: 'Senior Developer',
    },
    {
      photo: '/assets/teams/milan_pfp.png',
      name: 'Milan Kumar Behera',
      title: 'Developer',
    },
    {
      photo: '/assets/teams/shruti2_pfp.png',
      name: 'Shruti Gijare',
      title: 'UI/UX Designer',
    },
    {
      photo: '/assets/teams/rudra.jpg',
      name: 'Rudra Prasad Nayak',
      title: 'Junior Developer',
    },
    {
      photo: '/assets/teams/srinu.jpg',
      name: 'P. Srinivas Senapati',
      title: 'Junior Developer',
    },
    {
      photo: '/assets/teams/sarmistha.jpg',
      name: 'Sarmistha Panigrahi',
      title: 'Junior Developer',
    },
    {
      photo: '/assets/teams/priyansu.jpg',
      name: 'Priyansu Sekhar Palo',
      title: 'Junior Spring Boot Developer',
    },    
    {
      photo: '/assets/teams/abinash.jpg',
      name: 'G. Abinash',
      title: 'Graphics Designer',
    }, 
    // {
    //   photo: '/assets/teams/snehasis.jpg',
    //   name: 'Snehasis Mohanty',
    //   title: 'Developer',
    // },    
  ];

  return (
    <Box
      sx={{        
        bgcolor:'#020226',
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        padding: { xs: '10px', sm: '20px', md: '0px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          mt: '40px',
          pb:4,
        }}
      >
        <Typography sx={{ textAlign:'center', color: '#1578BE', fontSize: '40px',mt:1, fontWeight: '700' }}>
          Meet Our Tech Team
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',            
          }}
        >
          {teamMembers.map((member, index) => (
            <Box
              key={index}
              sx={{
                width: { xs: '50%', sm: '45%', md: '25%' },
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '220px',
                  height: '220px',
                  margin: '0 auto',
                }}
              >
                <CardMedia
                  component="img"
                  alt={member.name}
                  image="\assets\a.png" 
                  sx={{
                    objectFit: 'contain',
                    width: '160px',
                    height: '160px',
                    position: 'absolute',
                    top: '60px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: '1',
                    
                  }}
                />
                <CardMedia
                  component="img"
                  alt={member.name}
                  image={member.photo} 
                  sx={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    border: '2px solid white',
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: '10',
                  }}
                />
              </Box>

              
              <Typography sx={{ marginTop: '0px',mt:1, textAlign:'center', color:'#fff', fontWeight: 'bold' }}>
                {member.name}
              </Typography>
              <Typography sx={{color:'#fff',textAlign:'center',}}>{member.title}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
