import React from "react";
import { Box, Typography, Grid, CardMedia } from "@mui/material";
import { styled } from "@mui/system";

// Custom styled components
const HeaderBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  textAlign: "center",
});

const SectionBox = styled(Box)({
  backgroundColor: "#020226",
  padding: "2rem 0",
  color: "white",
  textAlign: "center",
});

const DividerWithCircles = () => (
  <Box
    sx={{
      m: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgcolor: "#020226",
    }}
  >
    <Box
      sx={{
        width: "12px",
        height: "12px",
        bgcolor: "#FCAF17",
        borderRadius: "50%",
      }}
    />
    <Box
      sx={{
        width: { xs: "60%", md: "40%" },
        height: "2px",
        bgcolor: "#FCAF17",
      }}
    />
    <Box
      sx={{
        width: "12px",
        height: "12px",
        bgcolor: "#FCAF17",
        borderRadius: "50%",
      }}
    />
  </Box>
);

const EmpoweringCommunities: React.FC = () => {
  return (
    <Box>
      {/* Header Section */}
      <HeaderBox>
        <Typography
          variant="h4"
          sx={{
            width: "fit-content",
            px: 1.5,
            py: 1.5,
            borderRadius: "15px",
            color: "#0A1562",
            bgcolor: "#D9D9D9",
            fontWeight: "bold",
            fontSize: { xs: "2rem", md: "2.5rem" },
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            mb: 2,
          }}
        >
          Empowering Communities and CSOs
        </Typography>
        <Typography
          sx={{
            color: "#FD4F13",
            fontWeight: 600,
            fontSize: { xs: "1.5rem", md: "2rem" },
          }}
        >
          Self-Reliant Communities Initiative (SRCI)
        </Typography>
        <DividerWithCircles />
      </HeaderBox>

      {/* Content Section */}
      <SectionBox
        sx={{
          px: { xs: 2, md: 8 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            width: { xs: "100%", md: "60%" },
            fontSize: "1.4rem",
            fontWeight: 400,
            lineHeight: 1.6,
            textAlign: "left",
            mb: { xs: 3, md: 0 },
          }}
        >
          <ul style={{ paddingLeft: 8 }}>
            <li>
              Encourages communities to understand and leverage the potential
              within their members to support each other.
            </li>
            <br />
            <li>
              Piloting in 5 communities to shift the mindset from dependency to
              self-reliance, fostering cooperation among community members to
              address local needs.
            </li>
            <br />
            <li>
              Supports local CSOs to align with community visions and fill
              critical gaps necessary for self-sufficiency.
            </li>
          </ul>
        </Typography>
        <CardMedia
          component="img"
          height="300"
          image="/assets/courses/communities1.png"
          alt="health Empowerment Event"
          sx={{
            borderRadius: "15px",
            width: { xs: "100%", md: "30%" },
            maxWidth: 400,
            mx: { xs: 0, md: 8 },
            my: { xs: 2, md: 0 },
          }}
        />
      </SectionBox>
      <SectionBox>
        <Grid container sx={{ my: 5, justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CardMedia
              component="img"
              height="300"
              image="/assets/courses/communities2.png"
              alt="Camp Image 1"
              sx={{ borderRadius: "25px", maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
      </SectionBox>
    </Box>
  );
};

export default EmpoweringCommunities;
